import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { fetchServices } from "../../../services/services.service";
import { Service } from "../../../features/service.slice";

const CarServices = () => {
  const [services, setServices] = useState<Service[]>([]);

  useEffect(() => {
    fetchServices().then((res) => {
      setServices(res?.data.result.filter((svc: Service) => svc.status));
    });
  }, []);

  return (
      <Container>
        {services.map((wash, index) => (
          <>
          <div className="text-center">
            <h2>{wash.name}</h2>
            </div>
            <Row key={index} className="align-items-center">
              <Col lg={6}>
                <div className="img-holder">
                  <img src={wash.images[0]} alt="" className="w-100" />
                </div>
              </Col>
              <Col lg={6}>
                <h3>{wash.name}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: wash.description,
                  }}
                ></p>
              </Col>
            </Row>
          </>
        ))}
      </Container>
  );
};

export default CarServices;
