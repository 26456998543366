
import ContainerSecondary from '../../Components/layouts/main/ContainerSecondary'
import HeroServices from './components/HeroServices'
import CarServices from './components/CarServices'

const ServicesMain = () => {
  return (
    
    <ContainerSecondary>
      <HeroServices/>
      
    <section className="servicesMain">
      <CarServices/>
      </section>
    </ContainerSecondary>
  )
}

export default ServicesMain